const filterListings = (listings, px) => {

  // Return unfiltered listings if px is null or doesn't match 'true'/'false'
  if (px === null) {
    return listings;
  }

  // Safely parse the 'min' and 'max' values, and set max equal to min if max is undefined
  return listings.filter(l => {
    const min = parseInt(l.attributes.publicData?.min, 10);
    let max = parseInt(l.attributes.publicData?.max, 10);

    // If max is undefined or NaN, set it equal to min
    if (isNaN(max)) {
      max = min;
    }

    if (px === 'true') {
      // Filter listings where min > 8
      return !isNaN(min) && min > 8;
    } else if (px === 'false') {
      // Filter listings where max < 9
      return !isNaN(max) && max < 9;
    }

    // Default: return all listings if px doesn't match 'true' or 'false'
    return true;
  });
};

export default filterListings;

